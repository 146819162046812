// Header
@media (max-width: 1552px) {
  .Polaris-ButtonGroup__Item--plain:not(:last-child) {
    display: none;
  }
}

//Shop domain name
.shop-domain__name-wrapper {
  .Polaris-Button__Content {
    flex: 1;
    min-width: 0;

    .Polaris-Button__Text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

// Media card
.Polaris-Card {
  &__Section {
    .flex {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1.5rem !important;
    }
    .Polaris-MediaCard.items-center {
      min-width: 250px;

      // image resize
      img {
        max-width: 100%;
        height: auto;
        vertical-align: middle;
        aspect-ratio: 16/9;
      }
    }
  }
}

// Theme install page - demo list column
@media (min-width: 769px) and (max-width: 1000px) {
  .demo-list__wrapper {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (max-width: 660px) {
  .demo-list__wrapper {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

// Setting Account -table
.Polaris-Layout {
  &__AnnotationWrapper {
    .Polaris-Card {
      &__Section {
        .Polaris-ButtonGroup {
          &__Item:nth-child(3) {
            .Polaris-Button.Polaris-Button--destructive.Polaris-Button--plain {
              margin-left: 0;
            }
          }
        }
      }

      table {
        @media (max-width: 450px) {
          .Polaris-TextStyle--variationCode {
            font-size: 0.9em;
          }
          .Polaris-TextStyle--variationPositive {
            font-size: 1.3rem;
          }
          .Polaris-Button.Polaris-Button--plain.Polaris-Button--iconOnly {
            font-size: 1.3rem;
          }
          .Polaris-TextStyle--variationStrong,
          .scope-description {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}

.Polaris-Modal-Dialog__Modal {
  @media (max-width: 768px) {
    bottom: 50%;
    transform: translateY(50%);
  }
}

// Setting stores -table
.settingStore-page__container {
  .Polaris-Card {
    .Polaris-Card__Section {
      padding-bottom: 7.5rem;
    }
  }
}
.purchase-code__options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// Theme install page - Card demo
.card-control {
  position: relative;
  margin-bottom: 3rem;

  .Polaris-ButtonGroup {
    position: absolute;
    right: 16px;
    top: 4.5rem;
  }
}

//Theme manage - Card control
.card-content {
  position: relative;
  margin-top: 0.5rem;

  @media (min-width: 1044px) and (max-width: 1230px) {
    margin-bottom: 4.5rem;
  }

  @media (max-width: 850px) {
    margin-bottom: 4.5rem;
  }

  .Polaris-Heading {
    @media (max-width: 513px) {
      font-size: 1.5rem;
    }
  }

  .tag-line {
    margin-top: 1rem;

    @media (max-width: 513px) {
      .Polaris-Stack > .Polaris-Stack__Item:nth-child(2) {
        width: 100%;
      }
    }
  }

  .Polaris-ButtonGroup {
    position: absolute;
    right: 0;
    top: -0.75rem;

    @media (min-width: 1044px) and (max-width: 1230px) {
      top: 6.5rem;
    }

    @media (max-width: 850px) {
      top: 6.5rem;
    }

    @media (max-width: 513px) {
      top: 10.5rem;
    }

    @media (max-width: 331px) {
      top: 12.5rem;
    }
  }
}

// Button group - login/register form

@media (min-width: 600px) and (max-width: 640px) {
  .form-header {
    .Polaris-FormLayout__Item:nth-child(2) {
      .form-login-methods {
        .Polaris-ButtonGroup {
          justify-content: center;
        }
      }
    }
  }
}

@media (max-width: 490px) {
  .Polaris-Layout {
    padding: 1.6rem 1.6rem 0 !important;
  }

  .form-header {
    .Polaris-FormLayout__Item:nth-child(2) {
      .form-login-methods {
        .Polaris-ButtonGroup {
          &__Item {
            width: 100%;
            max-width: 420px;
            button {
              width: inherit;
            }
          }
        }
      }
    }
  }

  .form-content {
    .Polaris-FormLayout--condensed {
      .Polaris-FormLayout__Item {
        width: 100%;
        flex-basis: 46%;
      }
    }
  }
}
