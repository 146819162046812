.wheel {
  width: 250px;
  height: 250px;
  position: relative;
  flex: 0 0 auto;
  &-inner {
    width: 100%;
    height: 100%;
    position: relative;
    //transform: rotate(75deg);
  }
  .sectors {
    transform: rotate(60deg);
    transform-origin: center;
  }
  @media (min-width: 1580px) {
    width: 320px;
    height: 320px;
  }
  @media (min-width: 1680px) {
    width: 400px;
    height: 400px;
  }
  @media (min-width: 2200px) {
    width: 450px;
    height: 450px;
  }
}
.lucky-wheel-text {
  text-transform: uppercase;
  fill: #fff;
  font-size: 2rem;
  font-weight: bold;
}
.wheel-wrapper {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 50px;
  padding-bottom: 80px;
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    z-index: 1;
    display: inline-block;
    width: 26px;
    height: 26px;
    border: none;
    border-radius: 50%;
    .Polaris-Icon {
      width: 12px;
      height: 12px;
    }
  }
}
.wheel-form {
  padding-left: 40px;
  max-width: 520px;
  flex: 1;
  color: var(--text-color);
  .input-group {
    margin-top: 3rem;
    gap: 1.5rem;
  }
  .text-desc {
    opacity: .6;
  }
  button {
    height: 50px;
    background: #000;
    width: 100%;
    box-shadow: none;
    border: none;
    color: var(--button-color, #fff);
    font-size: 1.4rem;
    font-weight: 700;
    border-radius: 3px;
    margin-top: 1.5rem;
    pointer-events: none;
  }
  input {
    height: 50px;
    background: var(--field-bg, #fff);
    width: 100%;
    padding: 0 20px;
    box-shadow: none;
    //border: 1px solid #ebebeb;
    border: none;
    font-size: 1.6rem;
    font-weight: 500;
    border-radius: 3px;
    & + input {
      margin-top: 20px;
    }
  }
  h2 {
    font-weight: bold;
    line-height: 1.375;
  }
}
