body {
  background: #fff !important;
}

.User-Layout {
  --p-text-primary: #FF5C00;
  --p-action-primary: #FF5C00;
  --p-action-primary-hovered: #e05609;
  --p-action-primary-pressed: #c04905;
  --p-interactive: var(--p-text-primary);
  min-height: 100vh;
  &--show-quote {
    display: grid;
    grid-template-columns: 720px minmax(0,1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
  &__logo {
    padding-bottom: 20px;
    z-index: 1;
    @media (max-width: 600px) {
      svg {
        width: 160px;
      }
    }
  }
  &__wrapper {
    flex: 1;
    padding: 80px 20px 0;
    display: flex;
    flex-flow: column;
    position: relative;
    .User-Layout--show-quote & {
      padding-top: 120px;
    }
    @media (max-width: 1000px) {
      padding-top: 50px;
    }
  }
  &__quote {
    height: 100vh;
    position: sticky;
    top: 0;
    @media (max-width: 1200px) {
      display: none;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    flex: 1;
    padding: 2rem 0;
  }
}
.form-container {
  max-width: 580px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.form-header {
  text-align: center;
  .Polaris-DisplayText--sizeSmall {
    font-size: 1.8rem;
  }
  .Polaris-Button--sizeLarge {
    margin-top: 2rem;
  }
  .Polaris-TextContainer > *:not(:first-child) {
    margin-top: 1.25rem;
  }
}
.form-divider,
.form-content {
  width: 100%;
}
.form-divider {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #bbb;
  font-weight: 600;
  padding: 30px 0;
  &__line {
    display: block;
    height: 1px;
    width: 100%;
    background: #ebebeb;
  }
}
.form-footer {
  @media (min-width: 1050px) {
    display: none;
  }
  .Polaris-DisplayText--sizeSmall {
    font-size: 1.6rem;
    text-align: center;
    font-weight: 500;
    a {
      color: var(--p-text-primary);
    }
  }
}
.form-header + .form-content {
  margin-top: 6rem;
}
@media (max-width: 600px) {
  .Polaris-DisplayText--mobile {
    br {
      display: none;
    }
  }
}
.User-Layout__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;
  .flex {
    gap: 16px;
    padding: 10px;
    justify-content: center;
    a {
      font-weight: 500;
    }
  }
  .trustpilot {
    position: relative;
    display: inline-flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    h4 {
      display: inline-flex;
      align-items: center;
      gap: 5px;
    }
  }
  @media (max-width: 1280px) {
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    > .flex {
      align-items: center;
    }
  }
  @media (max-width: 600px) {
    padding: 2rem 0 3rem;
    .flex {
      margin-top: 10px;
      flex-flow: row wrap;
      gap: 10px;
    }
  }
}
.form-login-methods {
  margin-top: 20px;
  .Polaris-ButtonGroup {
    justify-content: center;
  }
  .Polaris-Button__Icon {
   max-height: 20px;
    svg {
      vertical-align: middle;
    }
  }
  .Polaris-Button__Content {
    font-size: 13px;
  }
  @media (max-width: 600px) {
    .Polaris-ButtonGroup {
      justify-content: center;
    }
    .Polaris-Button {
      width: 220px;
      white-space: nowrap;
    }
  }
}
.header-action {
  position: fixed;
  top: 30px;
  right: 30px;
  .flex {
    align-items: center;
    gap: 10px;
    > span {
      font-weight: 500;
    }
  }
  @media (max-width: 1050px) {
    display: none;
  }
}