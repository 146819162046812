@import './responsive.modules.scss';

html,
body {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

#PolarisPortalsContainer,
.Polaris-Page,
.Polaris-Frame {
  --p-text-primary: #ff5c00;
  --p-action-primary: #ff5c00;
  --p-action-primary-hovered: #e05609;
  --p-action-primary-pressed: #c04905;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

* {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  td,
  th {
    border: 1px solid #ebebeb;
  }
}

.Polaris-Icon__Svg {
  color: var(--p-icon) !important;
}
.Polaris-Navigation__Item--selected .Polaris-Icon__Svg {
  color: var(--p-text-primary) !important;
}
.Polaris-InlineError__Icon {
  .Polaris-Icon__Svg {
    color: var(--p-text-critical) !important;
  }
}

.install-page,
.user-page {
  .Polaris-Frame {
    display: none;
  }
}

.Polaris-Header-Title {
  font-size: 2.5rem;
}

.Polaris-SkeletonThumbnail--sizeLarge {
  width: 100%;
  height: 250px;
}

.Polaris-Card {
  .Polaris-Card {
    box-shadow: none;
    border-bottom: 0.1rem solid var(--p-divider);
  }
}

.Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Details {
  max-width: 45rem !important;
}
.Polaris-AccountConnection__Content {
  > div:first-child {
    font-weight: 600;
    line-height: 2.4rem;
    margin: 0;
    font-size: 1.6rem;
  }
}
.Polaris-ResourceItem__Container {
  align-items: center;
}

.Polaris-ChoiceList__Title,
.Polaris-Label__Text {
  font-weight: 500;
}

.rte {
  font-size: 1.6rem;
  line-height: 1.5;
  color: #666;
  padding: 0 2rem;
  p {
    margin: 20px 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    margin: 24px 0 15px;
    line-height: 1.35;
  }
  li {
    line-height: 28px;
    &::marker {
      font-weight: 500;
    }
  }
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.6rem;
  }
  h3 {
    font-size: 2.4rem;
  }
  h4 {
    font-size: 2.2rem;
  }
  h5 {
    font-size: 1.8rem;
  }

  td,
  th {
    padding: 1rem;
    border-bottom: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    &:last-child {
      border-right: 0;
    }
    p:first-child,
    p:last-child {
      margin: 0;
    }
  }
}

.buy-later {
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
  font-weight: 500;
}

.Polaris-TextField__Suffix {
  .suffix-action {
    pointer-events: auto;
    cursor: pointer;
  }
}
.Polaris-InlineError {
  text-align: left;
}
.store-selector {
  .Polaris-Button__Text {
    color: var(--p-text);
    font-weight: 500;
  }
}
.Polaris-TopBar-Menu__ActivatorWrapper {
  padding-right: 1rem;
}

.center-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Polaris-Navigation__PrimaryNavigation {
  padding-top: 3rem;
}
@media (min-width: 40em) {
  .Polaris-DisplayText--sizeExtraLarge {
    line-height: 4.2rem;
    font-size: 3.6rem;
  }
}

@media (max-width: 450px) {
  body {
    font-size: 1.3rem;
  }
}